import LandingPageLinkRack from "Clutch/Organisms/LandingPageLinkRack";
import { useInView } from "react-intersection-observer";

const BrandsSection = ({ brands }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });

  const brandsMapped = brands.map((brand) => {
    return {
      displayValue: brand.displayName,
      url: brand.url,
      imageUrl: brand.imageUrl,
      noFollow: false,
    };
  });
  return (
    <>
      <div ref={ref}></div>
      <LandingPageLinkRack
        links={brandsMapped}
        showMoreLink={{ url: "/brands", text: "Shop All Brands" }}
        isBrandImage
        areImagesLoading={!inView}
      />
    </>
  );
};

export default BrandsSection;
