import React, { useState, useEffect } from "react";

import { useInView } from "react-intersection-observer";

import Image from "next/image";

import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";

import Carousel from "Clutch/Organisms/Carousel";

import Card from "Clutch/Atoms/Card";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import Svg from "Clutch/Atoms/Svg";

import styles from "./MrpEventSection.module.scss";

const MrpEventSection = ({}) => {
  const [content, setContent] = useState();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });

  useEffect(() => {
    if (inView) {
      const fetchEvents = async () => {
        try {
          const query = `query {
                        upcomingEvents {
                            scheduleEvents {
                                eventName
                                eventDescription
                                eventDate
                                track {
                                    trackName
                                }
                                tickets {
                                    ticketPurchaseLink
                                }
                            }
                        }
                      }`;

          const response = await GraphQLRequest("/graphql/content", query);

          console.debug("response", response);

          if (response?.upcomingEvents?.scheduleEvents) {
            setContent(response.upcomingEvents.scheduleEvents);
          }
        } catch (err) {
          console.log("err", err);
        }
      };
      fetchEvents();
    }
  }, [inView]);

  return (
    <>
      <div ref={ref}></div>
      {content ? (
        <Carousel extendGutters>
          {content.map((event, index) => {
            return (
              <Carousel.Item
                key={`event-${index}`}
                data-testid={`event-${index}`}
              >
                <Card noPadding className={styles.card}>
                  <div className={styles.card_internal}>
                    <div className={styles.card_image}>
                      <Image
                        src={
                          "https://content.speedwaymotors.com/OtherImages/ship_it_day.jpg"
                        }
                        fill
                        alt="Racing Event Image"
                      />
                    </div>
                    <div className={styles.card_content}>
                      <Typography font={"heading"} size={1}>
                        {event.eventName}
                      </Typography>
                      <Typography
                        className={styles.card_content_description}
                        font={"regular"}
                        size={0.875}
                      >
                        {event.eventDescription}
                      </Typography>
                      {(event.tickets || []).length ? (
                        <TextLink
                          className={styles.card_content_callout}
                          size={0.875}
                          href={event.tickets[0].ticketPurchaseLink}
                          intent={"action"}
                          targetBlank
                        >
                          <Svg
                            icon={"external_link"}
                            size={0.75}
                            className={styles.card_content_callout_svg}
                            intent={"action"}
                          />
                          {" Buy Tickets "}
                        </TextLink>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </Card>
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        false
      )}
    </>
  );
};

export default MrpEventSection;
