const start = new Date("2024-07-08")
const end = new Date("2024-07-15")

const isCawWeek = () => {
    try{
        const current = new Date();
        
        return start < current && current < end  || typeof window !== 'undefined' && window.location.href.includes('testCAW')
    }
    catch(e){
        console.error(e)
    }
    return false
}

export {isCawWeek}