import RecentHistoryBlock from "./RecentHistoryBlock";
import personalizedSubSectionTypes from "/constants/personalizedSubSectionTypes";
import styles from "./PersonalizedContent.module.scss";
import { useState } from "react";
import AccountBlock from "./AccountBlock";
import SignupBlock from "./SignupBlock";
import ListBlock from "./ListBlock";
import CartBlock from "./CartBlock";
import MyRacePassBlock from "./MyRacePassBlock";
import BuyItAgainBlock from "./BuyItAgainBlock";
import OrdersBlock from "./OrdersBlock";
import LoyaltyBlock from "./LoyaltyBlock/LoyaltyBlock";

const COLUMNCOUNT = 3;
const PersonalizedContentSection = ({ subSections, audience }) => {
    const [subsectionsWithNothing, setSubsectionsWithNothing] = useState([])
    const nothingToShowCallback = (sectionName) => {
        if (subsectionsWithNothing.includes(sectionName)) {
            return;
        }
        setSubsectionsWithNothing([...subsectionsWithNothing, sectionName])
    }

    const subsectionCount = subSections.length - subsectionsWithNothing.length;

    let columnCount = Math.min(subsectionCount, COLUMNCOUNT)
    if (subsectionCount <= 1 && typeof (window) !== "undefined" && window.innerWidth > 1240) {
        return false;
    }
    const supSectionsToShow = subSections.filter(x => !subsectionsWithNothing.includes(x)).slice(0, columnCount);
    return <div className={`${styles.section}`} style={{ "--columnCount": `${columnCount}` }}>
        {supSectionsToShow.map(x => <PersonlizedSectionTypeSortingGate key={x} nothingToShowCallback={() => nothingToShowCallback(x)} subsection={x} audience={audience} />)}
    </div>
}

const IsDisqualifiedForSpecialRules = (blockName, audience) => {
    if (audience.toLowerCase() !== 'race' && blockName === personalizedSubSectionTypes.myracepass)
        return true;

    if (typeof (window) !== "undefined") {
        if (personalizedSubSectionTypes.account == blockName && window.innerWidth <= 1240) {
            return true;
        }
    }
    return false;
}


const PersonlizedSectionTypeSortingGate = ({ subsection, nothingToShowCallback, audience }) => {

    if (IsDisqualifiedForSpecialRules(subsection, audience)) {
        nothingToShowCallback();
        return;
    }

    if (subsection === personalizedSubSectionTypes.recent_history) {
        return <RecentHistoryBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.cart) {
        return <CartBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.orders) {
        return <OrdersBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.buildlist) {
        return <ListBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.signup) {
        return <SignupBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.loyalty) {
        return <LoyaltyBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.buyitagain) {
        return <BuyItAgainBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.account) {
        return <AccountBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    } else if (subsection === personalizedSubSectionTypes.myracepass) {
        return <MyRacePassBlock nothingToShowCallback={nothingToShowCallback} sectionName={subsection} />
    }

    console.error(`Unsupported personalized content subsection: ${subsection}`);
    nothingToShowCallback();
    return false;
}

export default PersonalizedContentSection