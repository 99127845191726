import Skeleton from "Clutch/Atoms/Skeleton";
import styles from './PersonalizationInitialSkeleton.module.scss';

const PersonalizationInitialSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <Skeleton fullWidth height={520} />
      <Skeleton fullWidth height={520} />
      <Skeleton fullWidth height={520} />
    </div>
  );
};

export default PersonalizationInitialSkeleton;
