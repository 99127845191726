import Expander from "Clutch/Organisms/Expander/Expander";
import Section from "../../section/Section";

const Default = () => {
  return (
    <Section title={"About Speedway Motors"} dataTestId={"about_section"} audience={'default'}>
      <Expander
        closedHeight={"100px"}
        buttonProps={{ intent: false, fill: false, invert: false }}
        testid={"about"}
      >
        <p className={"p p-1"}>
          Speedway Motors has been providing race car parts and aftermarket
          accessories since 1952. We started as a “brick and mortar” performance
          parts retailer selling aftermarket auto parts and custom car parts to
          area enthusiasts and now, decades later, we are one of the top
          aftermarket retailer auto parts operations in the world. We maintain
          two retail shopping locations attached to distribution centers for in
          person shopping. Our primary racing car parts and performance shop is
          in Lincoln, Nebraska, while a second performance shop full of the
          latest race car parts and aftermarket accessories for your classic
          muscle car, hot rod, classic pickup, and more is in Phoenix, Arizona.
          A third distribution center in Kearneysville, West Virginia ensures
          our high performance parts gets to your door in a timely manner no
          matter where you are in the country.
          <br />
          <br />
          Today, shopping for performance parts online, such as race car parts
          and custom car parts, can be a nerve racking experience. Many a car
          parts website is nothing more than a drop shipper of aftermarket
          accessories with minimal, if any, technical staff support to help
          ensure the high performance parts you buy will work and fit your
          project vehicle. We offer the same performance parts online that we do
          in our retail locations, stocked in our distribution centers and ready
          to ship to you. We have a full staff of technical experts
          knowledgeable about circle track race car parts, performance parts for
          your classic muscle car, and aftermarket accessories for your hot rod
          that are just a phone call away to help you with your performance
          parts technical and fitment questions. <br />
          <br />
          Our classic performance shop way of doing business hasn’t changed just
          because we have a car parts website too. We can be your racing car
          parts store, in person or online with the best selection and pricing
          on the performance parts you need. As an aftermarket retailer of race
          car parts, custom car parts, and more, let us be your aftermarket auto
          parts solution. Shop for your performance parts online with us or
          visit one of our two retail performance shop locations in person to
          find the performance parts you need to get back behind the wheel of
          your vehicle quickly with the right custom car parts the first time.
        </p>
      </Expander>
    </Section>
  );
};

export default Default;
