import { useUserStore } from "@speedwaymotors/clutch/Stores/UserStore/UserStore";
import LandingPageLink from "Clutch/Molecules/LandingPageLink";

import Frame from "./CommonBlockStuff";

const AccountBlock = ({ sectionName }) => {
  const logoutUser = useUserStore((x) => x.logoutUser);
  const isRegistered = useUserStore((x) => x.context.isRegistered);

  return (
    <Frame title={"Your Account"} sectionName={sectionName}>
      {isRegistered ? (
        <LandingPageLink
          link={{ displayValue: "Log Out", callback: logoutUser }}
        />
      ) : (
        <LandingPageLink
          link={{
            displayValue: "Sign In/Register",
            url: "/Account/Login",
            nofollow: true,
          }}
        />
      )}

      <LandingPageLink
        link={{
          displayValue: "Order History",
          url: "/Account/OrderHistory",
          nofollow: true,
        }}
      />
      <LandingPageLink
        link={{
          displayValue: "Track My Order",
          url: "/Track/OrderLocator",
          nofollow: true,
        }}
      />
      <LandingPageLink
        link={{
          displayValue: "Start a Return",
          url: "/Track/ReturnLocator",
          nofollow: true,
        }}
      />
      <LandingPageLink
        link={{ displayValue: "My Lists", url: "/buildlist", nofollow: true }}
      />
      <LandingPageLink
        link={{
          displayValue: "My Garage",
          url: "/CustomerVehicle",
          nofollow: true,
        }}
      />
      <LandingPageLink
        link={{
          displayValue: "Account Settings",
          url: "/account",
          nofollow: true,
        }}
      />
    </Frame>
  );
};

export default AccountBlock;
